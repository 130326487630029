<template>
  <div class="dynamic-form-component">
    <el-form
      ref="dynamicForm"
      :model="dynamicForm"
      :rules="dynamicFormRules"
      label-width="120px"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col v-for="item in dataset" :key="item.id" :span="colCalc(item)">
          <SingleFormItem
            :key="item.id"
            :dataset="item"
            @update="updateHandler"
          />
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import SingleFormItem from "./components/singleFormItem.vue";
export default {
  name: "dynamic-form-component",
  components: {
    SingleFormItem,
  },
  props: {
    dataset: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        this.buildFormAndRules(datas);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      dynamicForm: {},
      dynamicFormRules: {},
    };
  },
  methods: {
    checkFormValidate() {
      return new Promise((resolve) => {
        this.$refs.dynamicForm.validate((valid) => {
          if (valid) {
            resolve(this.dynamicForm);
          }
        });
      });
    },
    updateHandler({ key, value }) {
      this.dynamicForm[key] = value;
    },
    buildFormAndRules(datas) {
      datas.forEach((data) => {
        const isRequire = data.propsList.filter(
          (item) => item.key === "require"
        )[0].value;
        const itemCode = data.propsList.filter((item) => item.key === "code")[0]
          .value;
        const itemDefault = data.propsList.filter(
          (item) => item.key === "default"
        )[0].value;
        if (isRequire) {
          this.$set(this.dynamicFormRules, itemCode, [
            {
              required: true,
              message: "请输入",
              trigger: ["blur", "change"],
            },
          ]);
        }
        this.$set(this.dynamicForm, itemCode, itemDefault);
      });
    },
    colCalc({ propsList }) {
      const Layout = propsList.filter((item) => item.key === "layout")[0].value;
      return Layout === "all" ? 24 : 12;
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-form-component {
}
</style>