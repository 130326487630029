var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-form-component" },
    [
      _c(
        "el-form",
        {
          ref: "dynamicForm",
          attrs: {
            model: _vm.dynamicForm,
            rules: _vm.dynamicFormRules,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.dataset, function (item) {
              return _c(
                "el-col",
                { key: item.id, attrs: { span: _vm.colCalc(item) } },
                [
                  _c("SingleFormItem", {
                    key: item.id,
                    attrs: { dataset: item },
                    on: { update: _vm.updateHandler },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }