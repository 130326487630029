<template>
  <div class="single-form-item">
    <el-form-item :label="label" :prop="prop" class="single-item">
      <template v-if="dataset.key === 'input'">
        <el-input
          v-model="value"
          :placeholder="placeHolder"
          :maxlength="maxlength"
          show-word-limit
          @input="updateHandler"
        ></el-input>
      </template>
      <template v-else-if="dataset.key === 'textarea'">
        <el-input
          type="textarea"
          v-model="value"
          :rows="3"
          :placeholder="placeHolder"
          :maxlength="maxlength"
          show-word-limit
          @input="updateHandler"
        ></el-input>
      </template>
      <template
        v-else-if="['selector', 'companySelector'].includes(dataset.key)"
      >
        <el-select
          style="width: 100%"
          v-model="value"
          :placeholder="placeHolder"
          :clearable="clearable"
          :multiple="multiple"
          :filterable="filterable"
          @change="updateHandler"
        >
          <el-option
            v-for="(item, index) in optionList"
            :value="item.value"
            :label="item.label"
            :key="index"
          ></el-option>
        </el-select>
      </template>
    </el-form-item>
  </div>
</template>

<script>
import request from "@/utils/request";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "single-form-item",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      value: null,
      placeHolder: "请输入",
      label: "未命名",
      maxlength: 20,
      prop: null,
      clearable: false,
      multiple: false,
      filterable: false,
      optionList: [],
    };
  },
  watch: {
    dataset: {
      handler(datas) {
        this.label = this.propsRender(datas, "label", "未命名");
        this.prop = this.propsRender(datas, "code", null);
        this.multiple = this.propsRender(datas, "multiple", false);
        this.filterable = this.propsRender(datas, "filterable", false);
        this.value = this.propsRender(
          datas,
          "default",
          this.multiple ? [] : ""
        );
        this.placeHolder = this.propsRender(datas, "placeHolder", "请输入");
        this.maxlength = this.propsRender(datas, "maxLength", 20);
        this.clearable = this.propsRender(datas, "clearable", false);
        if (this.multiple) {
          this.value =
            typeof this.value === "string" ? this.value.split(",") : this.value;
        }
        if (["selector", "companySelector"].includes(datas.key)) {
          this.optionRender(datas);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    optionRender(data) {
      console.log("data.propsList", data.propsList);
      const { type, optionList, serviceUrl, valueKey, labelKey, classifyCode } =
        data.propsList.filter(
          (item) => item.key === "options" || item.key === "companyOptions"
        )[0].config;
      if (type === "static") {
        this.optionList = optionList;
        this.badValueFilter();
      } else if (type === "inner") {
        request({
          url: serviceUrl,
          method: "get",
        }).then((res) => {
          this.optionList = res.map((item) => {
            return {
              value: item[valueKey],
              label: item[labelKey],
            };
          });
          this.badValueFilter();
        });
      } else if (type === "classify") {
        request({
          url:
            envInfo.bgApp.lookupPath +
            `/tenant/lookup/item/find/list?classifyCode=${classifyCode}`,
          method: "get",
        }).then((res) => {
          this.optionList = res.map((item) => {
            return {
              value: item["itemCode"],
              label: item["itemName"],
            };
          });
          this.badValueFilter();
        });
      }
    },
    badValueFilter() {
      // 处理多选时，key在options中没匹配到，但是下拉框组件把值回显上去的问题
      if (this.multiple) {
        this.value = this.value.filter((item) => {
          const filterTemp = this.optionList.filter(
            (single) => single["value"] == item
          );
          if (filterTemp && filterTemp.length) {
            return true;
          } else {
            // 如果没有匹配到，要把default的value值给清掉，否则影响必填
            this.dataset.propsList.forEach((prop) => {
              if (prop.key === "default") {
                prop.value = [];
              }
            });
            return false;
          }
        });
      }
    },
    updateHandler() {
      this.$emit("update", {
        key: this.prop,
        value: this.value,
      });
    },
    propsRender(datas, prop, defaultValue) {
      const { propsList } = datas;
      if (propsList && propsList.length) {
        for (let key of propsList) {
          if (key.key === prop) {
            return key.value || defaultValue;
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.single-form-item {
}
</style>