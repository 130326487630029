var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "single-form-item" },
    [
      _c(
        "el-form-item",
        {
          staticClass: "single-item",
          attrs: { label: _vm.label, prop: _vm.prop },
        },
        [
          _vm.dataset.key === "input"
            ? [
                _c("el-input", {
                  attrs: {
                    placeholder: _vm.placeHolder,
                    maxlength: _vm.maxlength,
                    "show-word-limit": "",
                  },
                  on: { input: _vm.updateHandler },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                }),
              ]
            : _vm.dataset.key === "textarea"
            ? [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 3,
                    placeholder: _vm.placeHolder,
                    maxlength: _vm.maxlength,
                    "show-word-limit": "",
                  },
                  on: { input: _vm.updateHandler },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                }),
              ]
            : ["selector", "companySelector"].includes(_vm.dataset.key)
            ? [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: _vm.placeHolder,
                      clearable: _vm.clearable,
                      multiple: _vm.multiple,
                      filterable: _vm.filterable,
                    },
                    on: { change: _vm.updateHandler },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  },
                  _vm._l(_vm.optionList, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { value: item.value, label: item.label },
                    })
                  }),
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }